/**
 *  @name: 新版merchant-商户模块接口
 *  @version: 3.0
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/merchant";

/**
 *商户信息
 */
// 【user022】-获取当前用户所属商户列表-云玉峰
export const findCurrentUserRelationMerchantList = () => {
  return http.get(`${prefix}/find/current/user/relation/merchant/list`);
};

// 获取窗口端左上角菜单的商户列表-云玉峰
export const merchantInfoOfCategoryGroup = data => {
  return http.get(`${prefix}/merchant/info/of/category/group`, data);
};

// 【merchant079】- 商户收银商户列表接口 - 刘院民
export function merchantToCollectMoney(data) {
  return http.get(`${prefix}/merchant/to/collect/money`, data);
}

// 【merchant080】- 项目消费项目列表接口 - 刘院民
export function userAdminMerchant(data) {
  return http.get(`${prefix}/user/admin/merchant`, data);
}

// 根据商户ids查询商户列表 - 刘院民
export const merchantBasicInfoByIds = data => {
  return http.get(`${prefix}/merchant/basic/info/by/ids`, data);
};

// 【merchant125】游小铺供应商审核接口（刘院民）
export function orderSaleChannel(data) {
  return http.get(`${prefix}/order/sale/channel`, data);
}

// 【merchant132】获取业态下商户列表接口
export function windowUserManageMerchantList(data) {
  return http.get(`${prefix}/window/user/manage/merchant/list`, data);
}

// 获取业态下一个商户所拥有的项目列表
export function merchantProjectList(data) {
  return http.get(`${prefix}/merchant/project/list`, data);
}

//查询当前登录账号角色，所具有的商户操作权限列表（订单列表查询条件时用） - 刘院民
export const subMerchantList = data => {
  return http.get(`${prefix}/sub/merchant/list`, data);
};

// 根据名称查询分销商列表接口-胡汉杰
export function merchantGroupList(data) {
  return http.get(
    `${prefix}/merchant/category/group/of/distributor/name`,
    data
  );
}
// 查询子景区列表-刘院民
export function subScenics(data) {
  return http.get(`${prefix}/merchant/of/sub/scenic/tree`, data);
}
// 通过商户id查询对应商户详情-家伟
export function merchantSettingInfo(data) {
  return http.get(`${prefix}/merchant/setting/info`, data);
}

// 通过商户获取 分时预约 + 订单信息采集(不需要传入商户id) http://192.168.1.50:40001/project/41/interface/api/3881
export function merchantSettingOrderInfo(data) {
  return http.get(`${prefix}/merchant/setting/order/info`, data);
}
// 根据业态获取用户管理商户列表
export function getmerchantListType(data) {
  return http.get(`${prefix}/merchant/list/of/merchant/type`, data);
}
// 判断强制退款+强制核销审批电话
export function merchantSettingRefundWrite(data) {
  return http.get(`${prefix}/merchant/setting/refund/or/write/off/call`, data);
}
