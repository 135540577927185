/**
 *@name: 信息中心接口
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/message`; // 前缀

/**
 *发送短信-用于会员等需要验证token的场景
 */
// 【暂无编号】- 发送短信验证码 - 刘院民
export const messageVerifyCode = data => {
  return http.post(`${prefix}/member/message/verify/code`, data);
};

/**
 *发送短信-用于验证码登录或不需要验证token的场景
 */
// 【yApi】- 发送短信验证码 - 刘院民
export const loginMessageVerifyCode = data => {
  return http.post(`${prefix}/message/verify/code`, data);
};

// 【强制发送短信验证码接口】 - 刘院民http://192.168.1.50:40001/project/41/interface/api/3980
export const sendMessageVerify = data => {
  return http.post(`${prefix}/send/message/verify`, data);
};
// 【校验短信验证码接口】 - 刘院民http:http://192.168.1.50:40001/project/41/interface/api/3981
export const verifyMessageVerify = data => {
  return http.post(`${prefix}/verify/message/verify`, data);
};
